@import url('https://fonts.googleapis.com/css2?family=Istok+Web:wght@700&display=swap');

body {
    font-family: 'Istok Web', sans-serif;
    background: #F6DCAC;
    color: #D7281A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    letter-spacing: 0.2em;
    text-transform: uppercase;
}

.game-container {
    background: #F6DCAC;
    padding: 20px; 
    align-items: center;
    text-align: center; 
    width: 100%;
    max-width: 500px;
    margin: 0 auto; 
    box-sizing: border-box;
}

h2 {
    margin: 10px 0;
    color: #D7281A;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  position: relative;
}

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h1 {
  margin: 0;
  color: #01204E;
  font-size: 3rem;
  line-height: 1;
  margin-left: 0;
}

.placeholder-text {
  font-size: 0.60rem;
  color: #F85525;
  margin-top: 0.25rem;
  margin-left: 0;
  margin-bottom: 10rem;
}

.tile-images {
  display: flex;
  align-self: flex-start;
  margin-left: 1rem;
  margin-right: 0;
  padding-top: 0.5rem;
}

.small-tile {
  width: 5vw;
  height: auto;
  max-width: 30px;
  object-fit: cover;
  margin-top: -0.3rem;
}

@media (max-width: 768px) {
  .title-container {
    padding: 0;
  }

  h1 {
    font-size: 2.5rem;
    margin-left: 0rem;
  }

  .placeholder-text {
    font-size: 0.5rem;
    margin-top: 0.15rem;
    margin-left: 0;
    margin-bottom: 10rem;
  }

  .tile-images {
    margin-right: 0;
  }

  .small-tile {
    width: 7vw;
  }
}

.mute-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 4rem;
}

.mute-button {
    background: none;
    border: none;
    color: #F85525;
    font-size: 26px;
}

@media (max-width: 768px) {
    .mute-container {
      margin-bottom: 0;
    }
    
    .mute-button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background: none;
        border: none;
        color: #F85525;
        font-size: 26px;
    }
}

#game-board { 
    display: grid; 
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 10px; 
    margin-bottom: 30px;
}

.tile-wrapper {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}

.tile {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    transition: border-color 0.3s ease, transform 0.2s ease;
}

.tile.locked {
    opacity: 0.5;
    pointer-events: none;
}

.tile.selected {
    border-color: #F85525;
    border-style: solid;
    border-width: 4px;
    transform: scale(1.05);
}

.tile:hover {
    transform: scale(1.02);
}

@media (max-width: 400px) {
    #game-board {
        grid-template-columns: repeat(3, minmax(60px, 1fr));
        gap: 5px;
    }

    .tile-wrapper {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
    }

    .tile {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }

    .tile.selected {
        transform: none;
    }

    .tile:hover {
        transform: none;
    }
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1rem;
    right: 0;
}

.button {
    text-transform: uppercase;
    text-align-last: right;
    letter-spacing: 0.1em;
    font-family: 'Istok Web', sans-serif;
    background: #F85525;
    border: none;
    color: #F6DCAC;
    padding: 0.9rem 0.1rem;
    font-size: 1.5rem; 
    margin: 0.1rem 0;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
    width: 380px;
}

.button:hover {
    transform: translateY(-5px);
}

.button:active {
    transform: translateY(2px);
    background: #F85525;
}

.button.play {
    background: #01204E;
}

.button.play-again {
  background-color: #01204E;
}

@media (max-width: 400px) {
    .button {
        width: 320px;
        font-size: 1.2rem;
    }
}

.high-score-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #F6DCAC;
    padding: 1rem;
    color: #01204E;
    text-align: center;
    border: 1px solid #01204E;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 1000;
}

.high-score-modal h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #01204E;
}

.high-score-modal p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #01204E;
}

.high-score-modal button {
    background: #F85525;
    border: none;
    color: #F6DCAC;
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.high-score-modal button:hover {
    background: #028391;
    transform: scale(1.05);
}

@media (max-width: 400px) {
    .high-score-modal button {
        width: 320px;
        padding: 0.7rem 1rem;
        font-size: 0.9rem;
    }
}

.contact-icons {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.icon-row .fa-icon {
    margin-bottom: 10px;
    color: #01204E;
}

.icon-row span {
    font-size: 0.85rem;
    color: #01204E;
    text-align: center;
}

@media (max-width: 768px) {
    .game-container {
        width: 100%;
        padding: 10px;
    }
}

@media (orientation: landscape) and (max-height: 600px) {
    body {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 20px;
    }

    .game-container {
        width: 90%;
        max-width: 100%;
        padding: 20px;
    }
}

.game-stats-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #F85525;
  padding: 5px 0;
  margin-top: 10px;
  gap: 2px;
  font-weight: bold;
}

.game-stat {
  margin-right: 5px;
}

.time-stat {
  color: #01204E;
  font-weight: bold;
}

.moves-stat {
  color: #F85525;
}

.combo-stat {
  color: #F85525;
}

@media (max-width: 400px) {
  .game-stats-container {
    font-size: 12px;
    gap: 1px;
  }
  .game-stat {
  margin-right: 3px;
  }
}

@keyframes shakeTile1 {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(10px);
  }
}

@keyframes shakeTile2 {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-10px);
  }
}

.shake1 {
  animation: shakeTile1 1.5s ease-in-out infinite;
}

.shake2 {
  animation: shakeTile2 1.5s ease-in-out infinite;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.tile.flip {
  animation: flip 0.5s ease-in-out forwards;
}